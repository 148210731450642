"use client";

import React, { ReactNode, useCallback, useEffect, useState } from "react";
import useEmblaCarousel, { UseEmblaCarouselType } from "embla-carousel-react";
import Image from "next/image";
import { cn } from "@/app/styling";
import secondImage from "../../../public/images/landing/carousel-2.jpg";
import logoSmall from "../../../public/images/landing/logo-small.svg";
import { Ellipsis } from "../../app/components/ellipsis";
import { NextButton, PrevButton, usePrevNextButtons } from "../carousel/arrows";

type EmblaCarouselType = Exclude<UseEmblaCarouselType[1], undefined>;

export default function FoundersCarouselSection() {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <div id="founders" className="h-[90vh] w-full">
      <div
        className="embla__viewport relative flex h-full flex-col overflow-hidden"
        ref={emblaRef}
      >
        <div className="embla__container h-full w-full">
          <div className="embla__slide">
            <FirstSlide />
          </div>
          <div className="embla__slide">
            <SecondSlide />
          </div>
          <div className="embla__slide">
            <CommonSlide
              num={1}
              title={
                <>
                  1:1 Unique Digital
                  <br />
                  Artwork by Guest
                  <br />
                  Artist
                </>
              }
              description={<>Membership Artwork Reveal</>}
            />
          </div>
          <div className="embla__slide">
            <CommonSlide
              num={2}
              title={
                <>
                  Calatrava | Cedano
                  <br />
                  Unveiling Event
                </>
              }
              description={<>Founders Only</>}
            />
          </div>
          <div className="embla__slide">
            <CommonSlide
              num={3}
              title={
                <>
                  Wildmavins Member
                  <br />
                  Welcome Box
                </>
              }
              description={null}
            />
          </div>
          <div className="embla__slide">
            <CommonSlide
              num={4}
              title={
                <>
                  The Calatrava |
                  <br />
                  Cedano Extra Añejo
                  <br />
                  Tequila
                </>
              }
              description={
                <>1:1000 Founders Edition Included with membership purchase.</>
              }
            />
          </div>
        </div>

        {/* Pagination */}
        <div
          className="embla__controls absolute bottom-4 left-0 right-0 grid
            place-content-center gap-6"
        >
          <div
            className="embla__dots flex flex-wrap items-center justify-end gap-4"
          >
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={cn(
                  `embla__dot m-0 flex h-3 w-3 cursor-pointer touch-manipulation
                  appearance-none items-center justify-center rounded-[50%]
                  border-[1px] border-[#33363D] bg-[#EAE7E4] p-0`,
                  index === selectedIndex &&
                    "embla__dot--selected bg-[#987654]",
                )}
              />
            ))}
          </div>
        </div>
        {/* TODO: disabled states */}
        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
      </div>
    </div>
  );
}

function FirstSlide() {
  return (
    <div className="bg-red flex h-full w-full flex-col">
      <div
        style={{
          backgroundImage: "url('./images/landing/carousel-1.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="relative flex h-full w-full flex-col items-center
          justify-center bg-[#EAE7E4]"
      >
        <div className="mx-8 flex max-w-[316px] flex-col gap-12">
          <Image
            src={logoSmall}
            role="presentation"
            alt="Wildmavins small logo"
            className="mx-auto w-20"
          />
          <h2
            className="text-center font-franklinGothic text-[5rem] font-semibold
              uppercase leading-[5rem] tracking-[-0.01rem] text-[#EAE7E4]"
          >
            Founders House
          </h2>
          {/* TODO: Missing link on mobile */}
          <p
            className="mx-auto font-sctoGrotesk text-2xl font-normal uppercase
              leading-[1.75rem] tracking-[-0.01rem] text-[#EAE7E4]"
          >
            1000 Members
          </p>
        </div>
      </div>
    </div>
  );
}

function SecondSlide() {
  return (
    <div
      className="relative flex h-full w-full flex-col bg-[#987654] lg:flex-row"
    >
      <div className="relative m-8 h-[calc(100%-4rem)] overflow-hidden lg:w-full">
        <Image
          src={secondImage}
          alt=""
          role="presentation"
          fill
          className="object-cover object-[57%_50%]"
        />
      </div>
      <div
        className="relative mb-12 flex flex-col items-center justify-center
          gap-8 lg:mb-0 lg:min-w-[370px]"
      >
        <p
          className="text-center font-sctoGrotesk text-[2rem] uppercase
            leading-[1.875rem] tracking-[-0.05rem] text-[#EAE7E4] lg:pl-8
            lg:pr-16"
        >
          The founders <wbr />
          membership <wbr />
          includes:
        </p>
        <Image
          src={logoSmall}
          role="presentation"
          alt="Wildmavins small logo"
          className="mx-auto w-12 lg:absolute lg:bottom-8 lg:right-8"
        />
      </div>
    </div>
  );
}

function CommonSlide({
  num,
  title,
  description,
}: {
  num: number;
  title: ReactNode;
  description: ReactNode;
}) {
  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center gap-12
        bg-[#EAE7E4] p-2 font-sctoGrotesk"
    >
      <Ellipsis radius="big" size="big" index={num} />
      <p
        className="min-h-[86px] text-center text-[2rem] font-medium uppercase
          leading-[2rem] tracking-[-0.05rem] text-[#33363D]"
      >
        {title}
      </p>
      <p
        className="text-center text-[1.313rem] font-normal leading-[1.438rem]
          tracking-[-0.04rem] text-[#33363D]"
      >
        {description}
      </p>
    </div>
  );
}

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

/**
 * @see https://codesandbox.io/s/s2rxms?file=/src/js/EmblaCarouselDotButton.tsx:146-1407
 */
export const useDotButton = (
  emblaApi: EmblaCarouselType | undefined,
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

type PropType = React.ComponentPropsWithRef<"button">;

export const DotButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
};
