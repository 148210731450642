import { z } from "zod";

export const SearchParamsSchema = z.object({
  invitation: z.string().length(64),
});

export const SuccessfullInvitationSearchParamsSchema = z.object({
  invitation: z.string().length(64),
  givenName: z.string().min(1, "Given name is required"),
  familyName: z.string().min(1, "Family name is required"),
  inviteLink: z.string().url(),
});

export const envSchema = z
  .object({
    NEXT_PUBLIC_PRIVY_APP_ID_ADMIN: z.string().min(1),
    NEXT_PUBLIC_PRIVY_APP_ID_CLIENT: z.string().min(1),
    NEXT_PUBLIC_PRIVY_APP_ID: z.string().min(1),
    NEXT_PUBLIC_PRIVY_CLIENT_ID_CLIENT: z.string().min(1),
    NEXT_PUBLIC_MOONPAY_API_KEY: z.string().min(1),
    NEXT_PUBLIC_CONTRACT_ID: z.string().min(1),
    PRIVY_APP_SECRET_ADMIN: z.string().min(1),
    AWS_ACCESS_KEY_ID: z.string().min(1),
    AWS_SECRET_ACCESS_KEY: z.string().min(1),
    AWS_S3_BUCKET_NAME: z.string().min(1),
    AWS_FILE_PATH: z.string().url(),
    MAILCHIMP: z.string().min(1),
    HOST: z.string().url(),
    NODE_ENV: z.enum(["development", "production", "staging"]),
    CROSSMINT_SERVER_KEY: z.string().min(1),
    NEXT_PUBLIC_CROSSMINT_CLIENT_API_KEY: z.string().min(1),
    NEXT_PUBLIC_CROSSMINT_COLLECTION_ID: z.string().min(1),
    SUMSUB_APP_TOKEN: z.string().min(1),
    SUMSUB_SECRET_KEY: z.string().min(1),
    SUMSUB_BASE_URL: z.string().url(),
    NEXT_PUBLIC_FOUNDERS_HOUSE_PRICE_USD: z.string().min(1),
    WEBHOOK_SECRET: z.string().min(1),
  })
  .refine(
    () => {
      // Custom validation rules
      return true;
    },
    {
      message: "Environment validation failed",
    },
  );

export type Env = z.infer<typeof envSchema>;

declare global {
  namespace NodeJS {
    interface ProcessEnv extends Env {}
  }
}

export function assert(
  condition: boolean,
  message = "This value was promised to always be defined",
): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

export function validate<T extends z.ZodObject<any>>(
  schema: T,
  data: unknown,
): z.infer<T> {
  try {
    const validated = schema.parse(data);
    return validated;
  } catch (error) {
    if (error instanceof z.ZodError) {
      const missingVars = error.issues
        .map((issue) => `\r\n${issue.path.join(".")}: ${issue.message}`)
        .join("");
      throw new Error(`Validation error ${missingVars}`);
    }
    throw error;
  }
}
