import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-video@1.4.0_@types+react-dom@19.0.3_@types+react@19.0.7__@types+react@19.0.7_encoding@0._rwlzrkbw65wo4oh3fslfnmizb4/node_modules/next-video/dist/components/background-video.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/club.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/gabriel.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/logo-brown-small.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/logo-dark-small.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/logo-full-long.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/logo-full.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/logo-runda.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/logo-small.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/landing/marco.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["LandingMenu"] */ "/vercel/path0/src/app/components/landing-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/carousel/club-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/sections/founders-carousel.tsx");
