import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import { Env, envSchema } from "./validation";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export enum SpecialCharacter {
  NonBreakingSpace = "\xa0", // &nbsp;
  NonBreakinHyphen = "\u2011", // &shy;
}

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function validateEnv(): Env {
  try {
    const env = envSchema.parse(process.env);
    return env;
  } catch (error) {
    if (error instanceof z.ZodError) {
      const missingVars = error.issues
        .map((issue) => `\n${issue.path.join(".")}: ${issue.message}`)
        .join("");

      throw new Error(`❌ Invalid environment variables:${missingVars}`);
    }
    throw error;
  }
}

export enum LayoutId {
  Admin = "admin",
  Root = "root",
  Client = "client",
  VirtualWindow = "virtual-window",
  Authorized = "client-authorized",
  Onboarding = "client-authorized-onboarding",
}

export function pluralize(word: string, count: number) {
  return `${count} ${count === 1 ? word : word + "s"}`;
}

export function groupBy<T, K extends PropertyKey>(
  array: T[],
  callback: (item: T) => K,
): Partial<Record<K, T[]>> {
  return array.reduce(
    (acc, item) => {
      const key = callback(item);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    },
    {} as Partial<Record<K, T[]>>,
  );
}

export const hasElements = <T>(
  collection: T[] | readonly T[],
): collection is NonEmptyArray<T> => collection.length > 0;

export const isSerializedJSON = (str: string): boolean => {
  try {
    const parsed = JSON.parse(str);
    return typeof parsed === "object" && parsed !== null;
  } catch {
    return false;
  }
};
