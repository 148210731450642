import { ReactNode } from "react";

export function Ellipsis({
  index,
  color = "brown",
  size = "normal",
  radius = "normal",
}: {
  index: ReactNode;
  color?: "brown" | "dark" | "white";
  size?: "normal" | "big";
  radius?: "normal" | "big";
}) {
  const borderColor =
    color === "brown"
      ? "border-[#987654]"
      : color === "white"
        ? "border-[#EAE7E4]"
        : "border-[#33363D]";
  const textColor =
    color === "brown"
      ? "text-[#987654]"
      : color === "white"
        ? "text-[#EAE7E4]"
        : "text-[#33363D]";
  const shapeSize =
    size === "normal" ? "h-[54px] w-[100px]" : "h-[63px] w-[117px]";
  const radiusSize =
    radius === "normal" ? "border-[0.125rem]" : "border-[0.25rem]";
  return (
    <div
      className={`inline-flex shrink-0 items-center justify-center rounded-[50%]
        bg-transparent font-normal ${borderColor} ${shapeSize} ${radiusSize}`}
    >
      <span
        className={`font-sctoGrotesk text-2xl font-bold leading-[1.625rem]
          tracking-[-0.05rem] ${textColor}`}
      >
        {index}
      </span>
    </div>
  );
}
