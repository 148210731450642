"use client";

import { PrivyProvider } from "@privy-io/react-auth";

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <PrivyProvider
      appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID_CLIENT}
      clientId={process.env.NEXT_PUBLIC_PRIVY_CLIENT_ID_CLIENT}
      config={{
        captchaEnabled: true,
        loginMethods: ["email", "google"],
        appearance: {
          showWalletLoginFirst: false,
          logo: "/images/WM-Logotype-NoBG-Basalt_2x.png",
        },
      }}
    >
      <div className="min-h-screen w-full">{children}</div>
    </PrivyProvider>
  );
}
