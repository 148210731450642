"use client";

import { cn } from "@/lib/utils";
import * as Dialog from "@radix-ui/react-dialog";
import { Menu, X } from "lucide-react";
import { useState } from "react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { useLogin } from "@privy-io/react-auth";
import { getUsedInvitation } from "../(client)/(with-privy)/(logged-in-only)/actions";
import { useRouter } from "next/navigation";

const menuItems = [
  {
    title: "Home",
    href: "#hero",
  },
  {
    title: "Membership",
    href: "#membership",
  },
  {
    title: "Club Architecture",
    href: "#club-architecture",
  },
  {
    title: "Meet the creators",
    href: "#meet-the-creators",
  },
  {
    title: "Contact",
    href: "#contact",
  },
] as const;

// don't say "Profile" if the user doesn't have one
export function LandingMenu() {
  const router = useRouter();
  const [loginButton, setLoginButton] = useState({ title: "Login", url: "#" });
  const { login } = useLogin({
    /**
     * There are 4 cases:
     *
     * 1. You're a stranger, you're trying to log in but you are NOT yet a member
     * 2. You're already a Wildmavins user, you're trying to log in and you ARE a member
     * 3. You're being "automatically logged in" but you are NOT a member (repeated visit)
     * 4. You're being "automatically logged in" and you ARE a member (repeated visit)
     */
    onComplete: async (user, isNewUser, wasAlreadyAuthenticated) => {
      // Cases 3 and 4 (refreshed page, repeated visit)
      if (wasAlreadyAuthenticated) {
        try {
          const invitation = await getUsedInvitation(user!.id);

          if (!isNewUser && invitation) {
            const { code } = invitation;

            // Case 4)
            setLoginButton({
              title: "Profile",
              url: `/profile?invitation=${code}`,
            });
          } else {
            // Case 3)
            setLoginButton({ title: "Login", url: "/not-member" });
          }
        } catch (error) {
          // console.error(error);

          // Case 3) too
          setLoginButton({ title: "Login", url: "/not-member" });
        }
      } else {
        // You've been logged in for the first time (whether you've created a WM account before or not)

        try {
          const invitation = await getUsedInvitation(user!.id);

          if (!isNewUser && invitation) {
            const { code } = invitation;

            // Case 2)
            router.push(`/profile?invitation=${code}`);
          } else {
            // Case 1)
            router.push(`/not-member`);
          }
        } catch (error) {
          console.error(error);
          router.push(`/not-member`);
        }
      }
    },
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <div className="relative">
      <div className="relative md:hidden">
        <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
          <Dialog.Trigger asChild>
            <button
              className="mx-4 flex flex-row items-center rounded-full px-4 py-4
                text-[#EAE7E4] transition-colors hover:bg-transparent md:hidden"
              aria-label="Open menu"
            >
              <h2
                className="mr-4 mt-[0.5rem] font-franklinGothic text-[2rem]
                  font-semibold uppercase leading-[1.625rem]"
              >
                Menu
              </h2>
              <Menu className="h-6 w-6" />
            </button>
          </Dialog.Trigger>
          {/* https://github.com/radix-ui/primitives/pull/2923#discussion_r1658953421 */}
          {/* Use container={globalThis.document?.body} if the error reappears */}
          <Dialog.Portal>
            <Dialog.Overlay
              className={cn("fixed inset-0 z-50 bg-[#EAE7E4] md:hidden")}
            >
              <Dialog.Content
                className={cn("fixed inset-0 z-50 flex flex-col")}
              >
                <VisuallyHidden.Root asChild>
                  <Dialog.Title>Menu</Dialog.Title>
                </VisuallyHidden.Root>
                <div
                  className="flex items-center justify-between px-4
                    py-[2.063rem]"
                >
                  <h1
                    className="h-12 font-franklinGothic text-5xl font-semibold
                      uppercase leading-[3.875rem] tracking-[0.2px]
                      text-[#33363D] hover:text-gray-500"
                  >
                    Menu
                  </h1>
                  <Dialog.Close
                    className="rounded-full px-4 py-[0.7rem] text-[#33363D]
                      transition-colors"
                  >
                    <X className="h-6 w-6" />
                    <span className="sr-only">Close menu</span>
                  </Dialog.Close>
                </div>
                <nav className="flex flex-col gap-6 px-4 py-8">
                  {menuItems.map((item) => (
                    <a
                      key={item.href}
                      href={item.href}
                      onClick={() => handleOpenChange(false)}
                      className={cn(
                        `font-sctoGrotesk text-[2rem] font-medium
                        leading-[2.125rem] tracking-[0.2px] text-[#33363D]
                        transition-colors hover:text-gray-500`,
                      )}
                    >
                      {item.title}
                    </a>
                  ))}
                  <a
                    href={loginButton.url}
                    {...(loginButton.url === "#" && { onClick: login })}
                    className={cn(
                      `font-sctoGrotesk text-[2rem] font-medium
                      leading-[2.125rem] tracking-[0.2px] text-[#33363D]
                      transition-colors hover:text-gray-500`,
                    )}
                  >
                    {loginButton.title}
                  </a>
                </nav>
              </Dialog.Content>
            </Dialog.Overlay>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
      {/* Desktop navigation */}
      <nav
        className="mr-8 hidden h-[7.125rem] flex-row items-center
          gap-[2.8125rem] md:flex"
      >
        {menuItems.map((item) => (
          <a
            key={item.href}
            href={item.href}
            className="p-2 font-franklinGothic text-lg font-semibold uppercase
              leading-[0.875rem] text-[#EAE7E4] transition-colors
              hover:text-white"
          >
            {item.title}
          </a>
        ))}
        <a
          href={loginButton.url}
          {...(loginButton.url === "#" && { onClick: login })}
          className={cn(
            `p-2 font-franklinGothic text-lg font-semibold uppercase
            leading-[0.875rem] text-[#EAE7E4] transition-colors hover:text-white`,
          )}
        >
          {loginButton.title}
        </a>
      </nav>
    </div>
  );
}
