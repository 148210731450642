import React, {
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { EmblaCarouselType } from "embla-carousel";

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = ComponentPropsWithRef<"button">;

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button
      className="embla__button embla__button--prev absolute left-6
        top-[calc(50%-1.25rem)] hidden h-10 w-10 rounded border-white lg:block"
      type="button"
      {...restProps}
    >
      <svg
        className="embla__button__svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L14.4142 19H28C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21H14.4142L19.7071 26.2929C20.0976 26.6834 20.0976 27.3166 19.7071 27.7071C19.3166 28.0976 18.6834 28.0976 18.2929 27.7071L11.2929 20.7071C11.1054 20.5196 11 20.2652 11 20C11 19.7348 11.1054 19.4804 11.2929 19.2929L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929Z"
          fill="white"
        />
      </svg>
      {children}
    </button>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button
      className="embla__button embla__button--next absolute right-6
        top-[calc(50%-1.25rem)] hidden h-10 w-10 rounded border-white lg:block"
      type="button"
      {...restProps}
    >
      <svg
        className="embla__button__svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2929 27.7071C19.9024 27.3166 19.9024 26.6834 20.2929 26.2929L25.5858 21L12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19L25.5858 19L20.2929 13.7071C19.9024 13.3166 19.9024 12.6834 20.2929 12.2929C20.6834 11.9024 21.3166 11.9024 21.7071 12.2929L28.7071 19.2929C28.8946 19.4804 29 19.7348 29 20C29 20.2652 28.8946 20.5196 28.7071 20.7071L21.7071 27.7071C21.3166 28.0976 20.6834 28.0976 20.2929 27.7071Z"
          fill="white"
        />
      </svg>
      {children}
    </button>
  );
};
